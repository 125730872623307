.tester{
  transition: all .9s ease-in-out;
}
.WebsiteRow{
  align-items: center;
  margin: 150px 0px;
}
.website-description{
  font-size: 1.5em;
}
.WebsiteRow-img{
  width: 650px;
}
.WebsiteRow-img.site{
  box-shadow: 3px 3px 6px rgb(151, 151, 151);
  border-radius: 9px;
}
#about-container-header{
  font-size: 3.8em;
}
#about-container-text{
  font-size: 1.8em;
}
#hero-container{
  min-height: 98vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.websiteTile{
  color:black !important;
}
/* desktop */
@media only screen and (min-device-width: 1225px) and (-webkit-min-device-pixel-ratio: 1) {
  #website-container {
    background-image: url("./websitebg.png");
    /* background-size: cover; */
    /* background-repeat: repeat-y; */
    background-position: -400px 0px;
  }
  #about-container{
    min-height: 80vh;
    padding: 200px 200px 0px 200px ;
  }
  #about-container-text-sec{
    padding-right:250px;
  }
  .WebsiteRow-left-content{
    padding-left: 200px !important;
    padding-right: 100px !important;
   }
   .WebsiteRow-right-content{
     padding-right: 200px !important;
     padding-left: 100px !important;
   }
   .WebsiteRow{
    min-height: 500px;
  }
  .pageIcon{
    font-size: 80px;
    margin-top: 50px;
  }
  .heroIcon{
    font-size: 80px;
    margin-top: 50px;
  }
  #faLightbulb{
    position: relative;
    left:-100px;
    color:#0ecd5d;
  }
#faCogs{
  position: relative;
  left:50px;
  color: #727272;
}
#faBrain{
  position: relative;
  left:-10px;
  color: #ed048cd8;
}
#faHome{
  position: relative;
  left:-200px;
  color:#f9a119;
}
}
/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  #website-container {
    background-image: url("./websitebg.png");
    /* background-size: cover; */
    /* background-repeat: repeat-y; */
    background-position: -1010px 0px;
  }
  #about-container{
    min-height: 80vh;
    padding: 50px 50px 0px 50px;
  }
  .WebsiteRow-left-content{
    padding-left: 50px !important;
    padding-right: 50px !important;
   }
   .WebsiteRow-right-content{
     padding-right: 50px !important;
     padding-left: 50px !important;
   }
   .WebsiteRow{
    min-height: 500px;
  }
  .pageIcon{
    font-size: 100px;
  }
  .heroIcon{
    font-size: 100px;
  }
  #faLightbulb{
    position: relative;
    left:-50px;
    color:#0ecd5d;
  }
#faCogs{
  position: relative;
  left:150px;
  color: #727272;
}
#faBrain{
  position: relative;
  left:-10px;
  color: #ed048cd8;
}
#faHome{
  position: relative;
  left:-170px;
  color:#f9a119;
}
.WebsiteRow.flipped{
  flex-direction: column-reverse;
}
}
/* ipad pro */
@media only screen and (min-device-width: 800px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  #website-container {
    background-image: url("./websitebg.png");
    /* background-size: cover; */
    /* background-repeat: repeat-y; */
    background-position: -1010px 0px;
  }
  #about-container{
    min-height: 80vh;
    padding: 50px 50px 0px 50px;
  }
  .WebsiteRow-left-content{
    padding-left: 50px !important;
    padding-right: 50px !important;
   }
   .WebsiteRow-right-content{
     padding-right: 50px !important;
     padding-left: 50px !important;
   }
   .WebsiteRow{
    min-height: 500px;
  }
  .pageIcon{
    font-size: 100px;
  }
  .heroIcon{
    font-size: 100px;
  }
  #faLightbulb{
    position: relative;
    left:-50px;
    color:#0ecd5d;
  }
#faCogs{
  position: relative;
  left:150px;
  color: #727272;
}
#faBrain{
  position: relative;
  left:-10px;
  color: #ed048cd8;
}
#faHome{
  position: relative;
  left:-170px;
  color:#f9a119;
}
.WebsiteRow.flipped{
  flex-direction: column-reverse;
}
}
/* ipad side */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1224px) and (-webkit-min-device-pixel-ratio: 1) {
  #website-container {
    background-image: url("./websitebg.png");
    /* background-size: cover; */
    /* background-repeat: repeat-y; */
    background-position: -1010px 0px;
  }
  #about-container{
    min-height: 80vh;
    padding: 100px 100px 0px 100px;
  }
  #about-container-text-sec{
    padding-right:100px;
  }
  .WebsiteRow-left-content{
    padding-left: 50px !important;
    padding-right: 50px !important;
   }
   .WebsiteRow-right-content{
     padding-right: 50px !important;
     padding-left: 50px !important;
   }
   .WebsiteRow{
    min-height: 500px;
  }
  .pageIcon{
    font-size: 100px;
  }
  .heroIcon{
    font-size: 100px;
  }
  #faLightbulb{
    position: relative;
    left:-100px;
    color:#0ecd5d;
  }
#faCogs{
  position: relative;
  left:50px;
  color: #727272;
}
#faBrain{
  position: relative;
  left:-10px;
  color: #ed048cd8;
}
#faHome{
  position: relative;
  left:-200px;
  color:#f9a119;
}
}
/* mobile */
@media only screen and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 1) {
  #hero-container{
    min-height: 94vh !important;
  }
  #website-container {
    background-image: url("./websitebg.png");
    /* background-size: cover; */
    /* background-repeat: repeat-y; */
    background-position:-1488px 0px;
  }
  .WebsiteRow-img{
    width: 100%;
  }
  #about-container-header{
    font-size: 3.2em;
  }
  #about-container-text{
    font-size: 1.2em;
  }
  .website-description{
    font-size: 1.1em;
  }
  .WebsiteRow.flipped{
    flex-direction: column-reverse;
  }
  .pageIcon{
    font-size: 50px;
  }
  .heroIcon{
    font-size: 50px;
  }
  #faLightbulb{
    position: relative;
    left:-30px;
    color:#0ecd5d;
  }
#faCogs{
  position: relative;
  left:80px;
  color: #727272;
}
#faBrain{
  position: relative;
  left:20px;
  color: #ed048cd8;
}
#faHome{
  position: relative;
  left:-100px;
  color:#f9a119;
}
}
